import React from 'react';
import _ from 'lodash';
import moment from 'moment';

moment.locale('de');
function Ribbon({ product, isOfferActive }) {

    if(!_.isNil(product.stock) && product.stock < 1 ){
        product.soldOut = true
    }
    
    let currentDate = moment(new Date());
    let createdDate = moment(new Date(product?.createdAt)).startOf("day");
    let result = currentDate.diff(createdDate, 'days')
    
    let isNewProduct = product.isOwnArticle && result < 14;

    let hasNoOffer = (product.originalPrice && product.preisAb && product.originalPrice === product.preisAb)  || 
                     !isOfferActive || 
                     ((product.aktionsPreis && product.aktionsPreis >= product.vk_preis) && (product.aktionsPreisPickup && product.aktionsPreisPickup >= product.pickup_preis))

    return (
        <>
        {product.soldOut &&  <div className="uk-text-default uk-position-z-index uk-position-top-left aps-soldout-ribbon soldout-theme">Bald wieder lieferbar</div> }
        {!product.soldOut && product.showRemainingStock &&  <div className="uk-text-bold uk-position-z-index uk-position-top-left aps-stock-ribbon stock-theme">Nur noch {product.stock} auf Lager</div> }
        <div className='uk-position-z-index uk-position-top-right'>
            { isNewProduct && <div className="uk-text-default aps-new-ribbon new-theme"> NEU </div> }    
            { !hasNoOffer && <div className="uk-text-default aps-angebote-ribbon angebote-theme"> % </div> }    
        </div>
        </>
    );
}

export default Ribbon;
