import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { InternalLink } from '../form-elements/Link';

function CouponModal({ children, coupon }) {

    const { startDate, endDate } = coupon;

    const [open, setOpen] = useState(false);

    function openCouponModal() {
        setOpen(true);
    }

    function closeCouponModal() {
        setOpen(false);
    }

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0'); 
        const month = (d.getMonth() + 1).toString().padStart(2, '0'); 
        const year = d.getFullYear();
        return `${day}.${month}.${year}`;
    };
    
    

    return (
        <>  {children({ openCouponModal })}
            <Modal isOpen={open} onRequestClose={closeCouponModal} contentLabel="Gutschein Modal">
                <div className="uk-flex uk-padding-small uk-padding-remove-top uk-flex-column uk-position-relative">
                    <div className="uk-flex uk-flex-column">
                        <div className="uk-position-absolute uk-position-top-right uk-padding-small">
                            <button
                                className="uk-icon-button uk-text-secondary"
                                data-uk-icon="close"
                                onClick={closeCouponModal}
                                data-uk-ttoltip="Kontakt schließen"
                                aria-label="Kontakt schließen" />
                        </div>

                        <div className="uk-flex uk-flex-row uk-flex-around uk-text-center uk-margin-top">
                            <h2 className="uk-flex uk-flex-center">Sparen Sie jetzt</h2>
                        </div>

                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <div className="uk-flex-row uk-text-center">
                                <h1 style={{ fontSize: 48 }}>
                                    {coupon.discountType === 'PercentageProductDiscount'
                                        // example: 10%
                                        ? `${coupon?.percentage} % auf`
                                        : coupon?.discountType === 'FixedProductDiscount'
                                            // example: 10€
                                            ? `${coupon?.productDiscount} € auf`
                                            :
                                            coupon?.discountType === 'PercentageCartDiscount' ? `${coupon?.percentage} %` : `${coupon?.cartDiscount} €`}
                                </h1>

                                {/* List of all included products / categories */}
                                <ul className="uk-text-left uk-list uk-text-center" ng-if="coupon.restrictions.includeProducts.length > 0 || coupon.restrictions.includeCategories.length > 0">
                                    {coupon.restrictions.includeProducts.map((product, index) => (
                                        <li onClick={closeCouponModal} key={index}>
                                            <InternalLink to={`/${product.elementCategory}/${product.elementSlug ?? product.elementId}`}>
                                                {product.elementName} - {product.elementId}
                                            </InternalLink>
                                        </li>
                                    ))}
                                    {coupon.restrictions.includeCategories.map(category => (
                                        <li onClick={closeCouponModal} key={category.categoryId}>
                                            <InternalLink to={`/${category.categoryId}`}>
                                                {category.categoryName}
                                            </InternalLink>
                                        </li>
                                    ))}
                                </ul>

                                {/* If array of included products / categories is empty show this */}
                                <h2>{coupon.restrictions.includeCategories.length > 0 || coupon.restrictions.includeProducts.length > 0 ? '' : 'auf Ihren Warenkorb'}</h2>
                            </div>

                            <div className="uk-flex-row uk-background-primary uk-text-default uk-flex-center uk-text-center" style={{ color: '#fff', cursor: 'pointer', alignSelf: 'center' }} onClick={() => navigator.clipboard.writeText(coupon?.code)}>
                                <p className="uk-card-title" style={{ fontSize: '24px', margin: 0 }}>{coupon?.code}</p>
                                <p className="uk-text-small" style={{ margin: 0, paddingBottom: 12 }}>Code kopieren</p>
                            </div>
                            <br></br>

                            <div className="uk-text-center" style={{ marginBottom: 20 }}>
                                {coupon?.restrictions.excludeCategories.length > 0 || coupon?.restrictions.excludeProducts.length > 0 ?
                                    <span>
                                        {/* <h5>{coupon?.restrictions.excludeCategories.length > 0 && 
                                        coupon?.restrictions.excludeProducts.length > 0 ? 
                                        <span>Gilt nicht für folgende Produkt/e / Kategorie/n</span> 
                                        : coupon?.restrictions.excludeCategories.length > 0 
                                        ? <span>Gilt nicht für folgende Kategorie/n</span> 
                                        : <span>Gilt nicht für folgende Produkt/e</span>}</h5> */}
                                        <h5>Gilt nicht für</h5>
                                        {/* List of all excluded products / categories */}
                                        <ul className="uk-text-left uk-list uk-text-center" style={{ marginBottom: 8 }}>
                                            {coupon.restrictions.excludeProducts.map(product => (
                                                <li key={product.elementId}>
                                                    {product.elementName} - {product.elementId}
                                                </li>
                                            ))}
                                            {coupon.restrictions.excludeCategories.map(category => (
                                                <li key={category.categoryName}>
                                                    {category.categoryName}
                                                </li>
                                            ))}
                                        </ul>
                                    </span>
                                    : null}
                                <br></br>
                                {startDate && !endDate && (
                                    <p><strong>Aktion gültig ab {formatDate(startDate)} </strong></p>
                                )}
                                {!startDate && endDate && (
                                    <p><strong>Aktion gültig bis {formatDate(endDate)} </strong></p>
                                )}
                                {startDate && endDate && (
                                    <p><strong>Aktion gültig vom {formatDate(startDate)} bis {formatDate(endDate)} </strong></p>
                                )}
                                {coupon?.restrictions.minimumSpend ? <p style={{ margin: 0 }}>Mindestbestellwert {coupon?.restrictions.minimumSpend} €</p> : null}
                                {coupon?.restrictions.maximumSpend ? <p style={{ margin: 0 }}>Maximalbestellwert {coupon?.restrictions.maximumSpend} €</p> : null}

                                {coupon?.restrictions.endDate ? <p>Gültig bis zum {coupon?.restrictions.endDate}</p> : <br></br>}
                            </div>

                            {/* <div className="uk-width-1-1"><InternalLink to={coupon?.link} modifier="secondary">Hier geht es zu den Angeboten</InternalLink></div> */}

                            <p className="uk-text-small">Wie Sie den Gutschein einlösen und mehr erfahren Sie unter <InternalLink to="/gutscheine">Gutscheine</InternalLink>.</p>

                            <p className="uk-text-small uk-text-justify">
                                Unsere Aktionsgutscheine für den Online-Shop erhalten Sie im Rahmen unserer Werbeaktionen.
                                Das erfolgt beispielsweise per E-Mail, Banner oder in Form eines Coupons. Pro Bestellung darf nur ein Aktionsgutschein eingelöst werden. Aktionsgutscheine werden
                                nicht in bar ausgezahlt. Ein Aktionsgutschein kann nicht mit einer anderen Aktion kombiniert
                                werden. Aktionsgutscheine werden nur auf den Warenwert, indes nicht auf Versandkosten
                                oder sonstige Zuschläge angerechnet.
                                Die Gültigkeit unserer Aktionsgutscheine kann auf bestimmte Produkte, Hersteller, Marken
                                oder Sortimentsbereiche beschränkt sein. Nach Ablauf des Gültigkeitszeitraumes oder nach
                                Bestellabschluss kann eine Einlösung der Gutscheine nicht mehr stattfinden. Das ergibt
                                sich auch aus der jeweiligen Aktionsgutscheinaktion.
                                Unsere Aktionsgutscheine können Sie nur im Rahmen unseres Online-Shops einlösen und nicht
                                in unseren Filialen. Aktionsgutscheine gelten nur während des angegebenen Aktionszeitraumes.
                                Ein späteres Einlösen ist leider nicht möglich. Der Aktionsgutschein wird nicht erstattet,
                                wenn die Ware ganz oder teilweise retourniert wird, sofern der Aktionsgutschein im Rahmen einer
                                Werbeaktion ausgegeben wurde und dafür keine  Gegenleistung  erbracht  wurde.
                                Gilt für den Aktionsgutschein ein Mindesteinkaufspreis, versteht sich dieser nach Abzug etwaiger
                                (Teil-) Waren-Retouren. Wird der Mindesteinkaufspreis nach (Teil-) Waren-Retouren unterschritten,
                                berechnen wir den ursprünglichen vollen Preis der Waren, die Sie behalten.
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default CouponModal;