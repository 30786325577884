import React, { useMemo } from 'react';
import classnames from 'classnames';
import Image from '../Image/Image';
import Ribbon from '../product-list/ribbon';
import { InternalLink } from '../form-elements/Link';
import Currency from '../../../helpers/currency';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import MerklisteButton from '../../routes/Merkliste/MerklisteButton';

function ProductCard({ product, forOffer }) {
    //console.log(product);
    const to = useMemo(() => {
        return product.href || `/${product.categories[0]}/${product.id}`;
    }, [product]);
    const markenStandort = useSelector(markenStandortSelector)
    const isNoa = markenStandort.gesellschafterNr === "035";
    let isOfferActive = product.isDeliveryOfferActive || product.isPickUpOfferActive;
    return (
        <div className="uk-card uk-card-default uk-card-small uk-flex uk-flex-column">
            <div className="uk-card-media-top uk-flex uk-flex-center uk-flex-middle" style={{ height: 235 }}>
                <Image src={product.imagelink} alt={product.bezeichnung} resizeHeight={235} fit="cover" />
            </div>
            {forOffer && <span className='artikelnummer'>Artikelnummer: {product.eigeneArtikelVarianten? product.eigeneArtikelVarianten[0]?.shownId : product.id}</span>}
            <div className="uk-card-body uk-flex uk-flex-column uk-flex-1">
                <Ribbon product={product} isOfferActive={isOfferActive}/>
                <InternalLink to={to} modifier="reset">
                    <h4 className="uk-card-title cardTitle" dangerouslySetInnerHTML={{__html: product.bezeichnung}}></h4>
                </InternalLink>
                <div className="uk-margin-auto-top">
                    <h4 className="uk-card-title uk-flex uk-flex-center">
                        {  
                        (product.promotion !== "none" && product.originalPrice && isOfferActive) ?
                        <div className={classnames('promotionalPriceBox', { 'noaPrice': isNoa })}>
                            <div className="oldPrice">                             
                                <>
                                <span>statt </span>
                                <span className='originalPrice'><Currency quantity={product.originalPrice} /></span>
                                </>     
                            </div>
                            <div className='newPrice'><Currency quantity={(product.preisAb || product.vk_preis)} /></div>
                        </div>
                        :
                        <div className={classnames('priceProductCard', { 'noaPrice': isNoa })}><Currency quantity={(product.preisAb || product.vk_preis)} /></div>
                        }
                    </h4>
                    <InternalLink to={to} fullWidth modifier="primary" className={product.soldOut? "soldout-theme" : ""}>
                        ZUM PRODUKT
                    </InternalLink>
                </div>
                {/* top 195 bacause Image is 235px high so 235 - 40px which is the heigth of the button */}
                <div style={{position: "absolute", right:0, top: 195}}>
                    <MerklisteButton productId={product.id} categoryUrl={product?.category ? product.category[0] : product?.categories[0]} selectedVariant={product.id} position={"relative"} />
                </div>

            </div>
        </div>
    );
}

export default ProductCard;
