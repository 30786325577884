import { connect } from "react-redux"
import { userSelector } from "../../../modules/selectors/auth"
import { merklistenSelector } from "../../../modules/selectors/merkliste"
import { addToMerkliste, removeFromMerkliste } from "../../../modules/actions/merkliste"
import { markenStandortSelector } from "../../../modules/selectors/standort/markenStandort"
import { toast } from "../../../helpers/toastHelper"
import { useEffect, useState } from "react"
import { useStandortHistory } from "../../components/VHost/StandortHistory"

let MerklisteButton = ({ markenStandort, user, merkliste, productId, categoryUrl, selectedVariant, position = "absolute", removeFromMerkliste, addToMerkliste }) => {
    //TODO: if user is logged in merkliste in state should be the same 
    // as the one saved for the user
    const history = useStandortHistory()
    useEffect(() => {
        window.merklistenRedirectHandler = function (path) {
            history.push({ pathname: path });
        }
    }, [history])

    let [isMerkliste, setIsMerkliste] = useState(false)
    const onClick = async () => {
        let entry = { productId, categoryUrl, selectedVariant, standort: markenStandort._id }

        if (merkliste && merkliste.length > 0 && merkliste.some(merklistenEntry => merklistenEntry.productId === productId && merklistenEntry.standort === markenStandort._id)) {
            await removeFromMerkliste(entry)
            toast({ message: "Vom Merkzettel entfernt!<button class='merklisten_redirect merklist' onClick={window.merklistenRedirectHandler('/merkliste')}>Merkzettel anzeigen</button>", status: 'success' });
        } else if(merkliste === undefined) {
            toast({ message: "Fehler beim Hinzufügen der Merkzettel! <button class='merklisten_redirect merklist' onClick={window.merklistenRedirectHandler('/merkliste')}>Merkzettel anzeigen</button>", status: 'error' });
        } else {
            await addToMerkliste(entry)
            toast({ message: "Gespeichert!<button class='merklisten_redirect' onClick={window.merklistenRedirectHandler('/merkliste')}>Merkzettel anzeigen</button>", status: 'success' });
        }
    }

    useEffect(() => {
        setIsMerkliste(window.location.pathname === "/merkliste")
    }, [])



    return (
        <div className="merkliste-button-wrapper" style={{ position }}>
            <button className="merkliste-button" onClick={async () => { await onClick() }}>
                <i className={isMerkliste ? "fa fa-trash-o" : merkliste && merkliste.some(entry => entry?.productId === productId && entry?.standort === markenStandort._id) ? "fa fa-heart" : "fa fa-heart-o"} />
            </button>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        merkliste: merklistenSelector(state),
        markenStandort: markenStandortSelector(state),
        user: userSelector(state),
    }
};

const mapDispatchToProps = { removeFromMerkliste, addToMerkliste };

export default connect(mapStateToProps, mapDispatchToProps)(MerklisteButton);
